import axios from "axios"
import { adminAction } from "./adminAction";

export const getDivName = (name) => (dispatch, getState) => {
    dispatch({ type: 'DIV_NAME', payload: name });
}

export const reelAppUsers = (app) => (dispatch, getState) => {
    dispatch({ type: 'LOADING_USERS' });
    axios({
        method: 'POST',
        url: app.endpoints.fetch_users,
        data: { secret_key: app.secret_key },
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {
        if (res.data.status === true) {
            dispatch({ type: 'REELAPPS_USERS', payload: res.data.data });
        } else {
            dispatch({ type: 'ERROR', payload: res.data.message });
        }
    }).catch(error => {
        console.log(error.message);
    });
}

export const addReelAppUsers = (user, setLoader, currentApp) => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: getState().app.currentApp.endpoints.add_user,
        data: user,
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {
        if (res.data.status === true) {
            dispatch({ type: 'ADD_REELAPP_USER', payload: res.data.data });
            dispatch({ type: 'SUCCESS', payload: res.data.message });
            dispatch(reelAppUsers(currentApp))
            const userData = {
                action: 'Add User',
                json_data: user,
                user_id: res.data.data.id
            };
            dispatch(adminAction(userData));

        } else {
            dispatch({ type: 'ERROR', payload: res.data.message });
        }

        setLoader({ loader: false, buttonText: 'Submit' });
    }).catch(error => {
        setLoader({ loader: false, buttonText: 'Submit' });
    })
};
export const addCredit = (user, setLoader, currentApp) => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: getState().app.currentApp.endpoints.totalCredit,
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {
        if (res.data.status === true) {
            dispatch({ type: 'ADD_CREDIT', payload: res.data.data });
            dispatch({ type: 'SUCCESS', payload: res.data.message });
            dispatch(reelAppUsers(currentApp))
            const userData = {
                action: 'Add User',
                json_data: user,
                user_id: res.data.data.id
            };
            dispatch(adminAction(userData));

        } else {
            dispatch({ type: 'ERROR', payload: res.data.message });
        }

        setLoader({ loader: false, buttonText: 'Submit' });
    }).catch(error => {
        setLoader({ loader: false, buttonText: 'Submit' });
    })
};

//------------------------------rehan
export const addReelAppBulkUsers = (user, setLoader) => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: getState().app.currentApp.endpoints.add_bulk_user,
        data: user,
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {
        if (res.data.status === true) {
            dispatch({ type: 'SUCCESS', payload: res.data.message });
            dispatch({ type: 'ADD_REELAPP_USER', payload: res.data.data });
            const userData = {
                action: 'Add User',
                json_data: user,
                user_id: res.data.data.id
            };
            //dispatch(adminAction(userData));

        } else {
            dispatch({ type: 'ERROR', payload: res.data.message });
        }

        setLoader({ loader: false, buttonText: 'Submit' });
    }).catch(error => {
        setLoader({ loader: false, buttonText: 'Submit' });
    })
};

//rehan-----------------------------------

export const updateDetails = (user, setLoader, currentApp) => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: getState().app.currentApp.endpoints.update_user_details,
        data: user,
        headers: { 'Content-type': 'application/json' }
    }).then(res => {
        setLoader({ loader: false, buttonText: 'Save Changes' });
        if (res.data.status === true) {
            console.log(user, "useruser")
            dispatch({ type: 'UPDATE_USER', payload: user });
            dispatch({ type: 'SUCCESS', payload: res.data.message });

            const userData = {
                action: 'Update User',
                json_data: user,
                user_id: user.id,
                user_email: user.email,


            };
            // dispatch(reelAppUsers(currentApp))

            dispatch(adminAction(userData));
        } else {
            dispatch({ type: 'ERROR', payload: res.data.message });
        }

    }).catch(error => {
        setLoader({ loader: false, buttonText: 'Save Changes' });
    })
}

export const changeEmail = (email, setLoader) => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: getState().app.currentApp.endpoints.change_user_email,
        data: email,
        headers: { 'Content-type': 'application/json' }
    }).then(res => {
        if (res.data.status) {
            dispatch({ type: 'CHANGE_EMAIL', payload: { email: email.email, id: email.id } });
            dispatch({ type: 'SUCCESS', payload: res.data.message });

            const userData = {
                action: 'Update Email Address',
                json_data: email,
                user_id: email.id
            };
            dispatch(adminAction(userData));
        } else {
            dispatch({ type: 'ERROR', payload: res.data.message });
        }
        setLoader({ loader: false, buttonText: "Update" });
    }).catch(error => {
        setLoader({ loader: false, buttonText: "Update" });
    });
};



export const appUserPassword = (data, setLoader) => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: getState().app.currentApp.endpoints.update_user_password,
        data: data,
        headers: { 'Content-type': 'application/json' }
    }).then(res => {
        if (res.data.status === true) {
            dispatch({ type: 'SUCCESS', payload: res.data.message });

            const userData = {
                action: 'Update Password',
                json_data: data,
                user_id: data.user_id
            };
            dispatch(adminAction(userData));
        } else {
            dispatch({ type: 'ERROR', payload: res.data.message });
        }
        setLoader({ loader: false });
    }).catch(error => {

        setLoader({ loader: false });
    })
};


export const appUserStatus = (data, setUser) => (dispatch, getState) => {
    setUser(data);
    axios({
        method: 'POST',
        url: getState().app.currentApp.endpoints.disable_user,
        data: data,
        headers: { 'Content-type': 'application/json' }
    }).then(res => {
        if (res.data.status === true) {
            dispatch({ type: 'SUCCESS', payload: res.data.message });
            dispatch({ type: 'UPDATE_USER_STATUS', payload: { id: data.id, status: data.status } });

            const userData = {
                action: 'Update User Status',
                json_data: data,
                user_id: data.user_id,
                reason: data.reason,
                user_email: data.email
            };
            dispatch(adminAction(userData));
        } else {
            dispatch({ type: 'ERROR', payload: res.data.message });
        }
    }).catch(error => {

    })
};


export const appUserDeleted = (data) => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: getState().app.currentApp.endpoints.delete_user,
        data: data,
        headers: { 'Content-type': 'application/json' }
    }).then(res => {

        if (res.data.status === true) {
            dispatch({ type: 'SUCCESS', payload: res.data.message });
            dispatch({ type: 'UPDATE_USER_DELETED', payload: { id: data.id, deleted: data.deleted } });

            const userData = {
                action: 'Update User Deleted Status',
                json_data: data,
                user_id: data.user_id,
                reason: data.reason,
                user_email: data.email
            };
            dispatch(adminAction(userData));

        } else {
            dispatch({ type: 'ERROR', payload: res.data.message });
        }
    }).catch(error => {

    })
};


export const appUserSuspend = (data) => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: getState().app.currentApp.domain + "api/suspend-user",
        data: data,
        headers: { 'Content-type': 'application/json' }
    }).then(res => {

        if (res.data.status === true) {
            dispatch({ type: 'SUCCESS', payload: res.data.message });
            dispatch({ type: 'UPDATE_USER_SUSPEND', payload: { id: data.id, suspend: data.is_suspend, reason: data.reason } });

        } else {
            dispatch({ type: 'ERROR', payload: res.data.message });
        }
    }).catch(error => {

    })
};
export const appUserWhitelist = (data) => (dispatch, getState) => {
    axios({
        method: 'POST',
        url: getState().app.currentApp.domain + "api/whitelist-email",
        url: getState().app.currentApp.domain + "api/whitelist-Email",
        data: data,
        headers: { 'Content-type': 'application/json' }
    }).then(res => {

        if (res.data.status === true) {
            dispatch({ type: 'SUCCESS', payload: res.data.message });
            dispatch({ type: 'UPDATE_USER_WHITELIST', payload: { id: data.id, is_whitelist: data.is_whitelist } });

        } else {
            dispatch({ type: 'ERROR', payload: res.data.message });
        }
    }).catch(error => {

    })
};
